//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

import StoresViewToolbar from '@/modules/stores/components/stores-view-toolbar.vue';
// import LoadingStoreInfo from '@/modules/stores/components/loading-store-info.vue';

import vueI18n from '@/vueI18n'
import { i18n } from '@/vueI18n';
import moment from 'moment';

import Roles from '@/security/roles';
import { StoresModel } from '@/modules/stores/stores-model';
const { fields } = StoresModel;

export default {
  name: 'app-stores-view-page',
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  components: {
    [StoresViewToolbar.name]: StoresViewToolbar,
    // [LoadingStoreInfo.name]: LoadingStoreInfo,
  },

  async mounted() {
    await this.doFindByVuexfire(this.id);
    // await this.doFind(this.id);
  },

  data() {
    return {
      dialog: false,
      model: null,
    }
  },
  computed: {
    ...mapGetters({
      record: 'stores/view/record',
      loading: 'stores/view/loading',
      toggleStatusloading: 'iam/view/statusLoading',
      
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    language(){
      return vueI18n.locale
    },
  },
  // firestore() {
  //   return {
  //     record: db.collection('user').doc(this.id),
  //   }
  // },
  methods: {
    ...mapActions({
      doFind: 'stores/view/doFind',
      doFindByVuexfire: 'stores/view/doFindByVuexfire',
      doToggleUserAccountStatus: 'iam/view/doToggleUserAccountStatus'
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },
    presenter(fieldName) {
      const val = StoresModel.presenter(this.record, fieldName);
      return val ? val : 'ـــ';
    },
    presenterEnum(fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == this.record[fieldName])
      return val.length ? val[0].label : 'ـــ';
    },
    presenterMap(fieldName) {
      const val = StoresModel.presenter(this.record, fieldName);
      return val[this.currentLanguageCode] ? val[this.currentLanguageCode] : 'ـــ'; 
    },
    presenterDateTime(fieldName) {
      const val = StoresModel.presenter(this.record, fieldName);
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
      // return val ? moment(val).locale(this.currentLanguageCode).format("dddd D MMM, YYYY") + this.i18n('common.at') + moment(val).locale(this.currentLanguageCode).format("hh:mm A") : 'ـــ';
    },
    presenterDate(fieldName) {
      const val = StoresModel.presenter(this.record, fieldName)
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(fieldName) {
      return moment(StoresModel.presenter(this.record, fieldName)).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(fieldName) {
      // let now = moment().locale('en').format('YYYY/MM/DD');
      // let time = StoresModel.presenter(this.record, fieldName)
      let n = this.record[fieldName]

      // console.log();
      // let date =  `${now} ${time}`;
      return moment(n,"YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    presenterStore(fieldName, key) {
      const user = StoresModel.presenter(this.record, fieldName);
      if (key == 'avatars' || key == 'avatar') {
        let avatar = null
        if (user && Array.isArray(user[key])) {
          avatar = user[key].length ? user[key][0].publicUrl : null
        } else if (user && user[key]) {
          avatar = user[key].publicUrl 
        }
        return avatar ? avatar : 'https://firebasestorage.googleapis.com/v0/b/puricom-beebe.appspot.com/o/public%2Fdefault%2Favatar.svg?alt=media&token=439fb6e6-815f-453e-9e3a-79dc0ab3d40c'
      }
      return user && user[key] ? user[key] : 'ـــ'; 
    },
    presenterClient(fieldName, key) {
      const val = StoresModel.presenter(this.record, fieldName);
      return val && val[key] ? val[key] : 'ـــ'; 
    },
    presenterLocalization(data) {
      if (typeof data === 'object' && data !== null) {
        return data[this.currentLanguageCode] ? data[this.currentLanguageCode] : data['en']
        // if (this.currentLanguageCode in data) {
        //   return data[this.currentLanguageCode]
        // }
      }
      return  
    },
    eventHandler(id) {
      console.log(id);
      this.doToggleUserAccountStatus(id)
    },

  },
};
