//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { StoresPermissions } from '@/modules/stores/stores-permissions';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-stores-view-toolbar',

  data() {
    return {
      
    }
  },
  computed: { 
    ...mapGetters({
      currentUser: 'auth/currentUser',
      record: 'stores/view/record',
      loading: 'stores/view/loading',
    }),
    hasPermissionToEdit() {
      return new StoresPermissions(this.currentUser).edit;
    },
  },
  methods: {
    ...mapActions({
      doToggleStatus: 'stores/view/doToggleStatus',
    }),
  },
};
